<template>
  <div>
    <img src='https://gitee.com/xqnode/images/raw/master/imgs/微信.png' width="300" />
  </div>
</template>

<script>
export default {
  name: "Donate"
}
</script>

<style scoped>

</style>
